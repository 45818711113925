.BUSINESS {
	&.first {
		.oneClum {
			margin: 42px 0 0;
			@media screen and (max-width:768px){
				margin: 24px 0 0;
			}
			>li {
				list-style: none;
				@include flex;
				align-items: center;
				background: #FFF;
				box-shadow: 0px 0px 34px 0.7px rgba(0, 0, 0, 0.11);
				margin: 0 0 32px;
				@for $i from 1 through 4{
					&:nth-child(#{$i}){
						dt {
							background: url("../../../images/business/icon_0#{$i}.png") no-repeat left 4px;
							background-size: 30px;
							@media screen and (max-width:768px){
								background-position: left 1px;
							}
							
						}
					}
				}
				@media screen and (min-width:769px){
					&:nth-child(even) {
						figure {
							order: 2;
						}
						dl {
							order: 1;
						}
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
				@media screen and (max-width:768px){
					margin: 0 0 4%;
				}
				figure {
					width: 35%;
					@media screen and (max-width:768px){
						width: 100%;
					}
					img {
						max-width: 100%;
						height: auto;
					}
				}
				dl {
					width: 65%;
					padding: 45px;
					@media screen and (max-width:768px){
						width: 100%;
						padding: 20px;
					}
					dt {
						font-size: 22px;
						font-weight: 700;
						padding: 0 0 6px 40px;
						margin: 0 0 12px;
						border-bottom: 1px solid $border;
						@media screen and (max-width:768px){
							font-size: 18px;
						}
					}
				}
			}
		}
	}
	
	&.third {
		background: rgba($blue,.10);
		&:before {
			content: none !important;
		}
		.swiper-pagination-bullet {
			background: $blue;
			border: 1px solid $blue;
		}
		
	}
}