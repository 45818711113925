.ENVIRONMENTAL {

	&.first {
		.check {
			background: #f5f5f5;
			width: 70%;
			margin: 42px auto;
			padding: 45px;
			@media screen and (max-width:768px){
				width: 100%;
				padding: 20px;
				margin: 24px 0;
			}
			>li {
				list-style: none;
				border-bottom: 1px solid #FFF;
				background: url("../../../images/environmental/icon_check.png") no-repeat left 0;
				background-size: 24px;
				padding: 0 0 8px 38px;
				margin: 0 0 12px;
				font-size: 16px;
				&:last-child {
					padding-bottom: 0;
					margin-bottom: 0;
					border-bottom: none;
				}
				@media screen and (max-width:768px){
					font-size: 13px;
				}
			}
		}
		.oneClum {
			counter-reset: num;
			>li {
				list-style: none;
				padding: 45px;
				margin: 0 0 32px;
				min-height: 180px;
				box-shadow: 0px 0px 34px 0.7px rgba(0, 0, 0, 0.11);
				position: relative;
				@media screen and (max-width:768px){
					padding: 20px;
					min-height: inherit;
					margin: 0 0 4%;
				}
				&:before {
					content: "";
					width: 100%;
					height: 100%;
					background: rgba(#FFF,.55);
					position: absolute;
					top: 0; left: 0;
				}
				@for $i from 1 through 3{
					&:nth-child(#{$i}){
						background: url("../../../images/environmental/back_0#{$i}.png") no-repeat left center;
						background-size: contain;
						@media screen and (max-width:768px){
							background-size: cover;
						}
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
				dl {
					position: relative;
					@include flex;
					align-items: center;
					dt {
						width: 42%;
						font-size: 22px;
						font-weight: 700;
						position: relative;
						@media screen and (max-width:768px){
							width: 100%;
							font-size: 16px;
							padding: 0 0 8px;
						}
						&:before {
							counter-increment: num;
							content: "INITIATIVES "counter(num, decimal-leading-zero);
							display: block;
							color: $blue;
							@include opensans;
							font-size: 12px;
							font-weight: 700;
						}
					}
					dd {
						width: 50%;
						@media screen and (max-width:768px){
							width: 100%;
						}
					}
				}
			}
		}
	}
}