@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700,800&display=swap");
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

main {
  display: block; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500; }

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold; }

/* BASE COLOR
------------------------------------------------------------*/
/* FONT SETTING
------------------------------------------------------------*/
/* SITE SETTING
------------------------------------------------------------*/
@media screen and (min-width: 769px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #000;
    background: #FFF;
    font-size: 14px;
    line-height: 1.8;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: 1120px;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

@media screen and (max-width: 768px) {
  * {
    margin: 0;
    padding: 0;
    zoom: 1; }
  *, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box; }
  html {
    -webkit-text-size-adjust: none; }
  body {
    color: #000;
    background: #EEE;
    font-size: 12px;
    line-height: 1.8;
    font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
    font-weight: 500;
    -webkit-font-feature-settings: "palt";
    font-feature-settings: "palt";
    letter-spacing: 0.024em; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500; }
  img {
    -ms-interpolation-mode: bicubic;
    vertical-align: bottom; }
  i, strong {
    font-style: normal; }
  a {
    text-decoration: none;
    outline: none;
    cursor: pointer;
    color: #D02; }
    a:hover {
      text-decoration: none;
      color: #ff1136; }
      a:hover img {
        opacity: .8; }
  .clear {
    clear: both; }
  .clearfix:after {
    content: "";
    display: block;
    clear: both; }
  .display {
    width: auto;
    margin: 0 auto;
    position: relative;
    top: 0;
    left: 0; }
  ul, ol {
    list-style: none; }
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="submit"],
  textarea {
    -webkit-appearance: none; } }

#HEADER {
  /* PC STYLE
	----------------------------------------*/
  /* SP STYLE
	----------------------------------------*/
  /* CUSTUM STYLE
	----------------------------------------*/
  padding: 0 0 0 30px !important; }
  @media screen and (min-width: 769px) {
    #HEADER {
      width: 100%;
      height: 80px;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0 0 0 0;
      z-index: 1000; }
      #HEADER .logo {
        float: left;
        margin: 20px 0 0 0; }
        #HEADER .logo img {
          width: 203px;
          height: auto; }
      #HEADER .gnavi {
        float: right;
        margin: 0 0 0 0; }
        #HEADER .gnavi li {
          list-style: none;
          margin-top: 0;
          float: left;
          margin-left: 40px; }
          #HEADER .gnavi li:first-child {
            margin-left: 0; }
        #HEADER .gnavi ul {
          display: block !important; } }
  @media screen and (max-width: 768px) {
    #HEADER {
      width: 100%;
      height: 60px;
      position: fixed;
      top: 0;
      left: 0;
      padding: 0 12px 0 12px;
      z-index: 1000; }
      #HEADER .logo {
        float: left;
        margin: 15px 0 0 0; }
        #HEADER .logo img {
          width: auto;
          height: 30px; }
      #HEADER .gnavi {
        float: right;
        margin: 14px 0 0 0;
        background: url(../../images/common/responsive_nav_2x.png) no-repeat 0 0;
        background-size: 32px 64px;
        width: 32px;
        height: 32px;
        cursor: pointer; }
        #HEADER .gnavi li {
          list-style: none;
          margin-top: 12px;
          float: none; }
        #HEADER .gnavi.addClose {
          background: url(../../images/common/responsive_nav_2x.png) no-repeat 0 -32px;
          background-size: 32px 64px; }
        #HEADER .gnavi ul {
          -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
          position: absolute;
          width: 100%;
          top: 60px;
          left: 0;
          display: none; }
          #HEADER .gnavi ul li {
            margin: 0;
            border-top: #DDD 1px solid; }
            #HEADER .gnavi ul li a {
              background: rgba(255, 255, 255, 0.95);
              color: #000;
              display: block;
              padding: 12px 12px; }
              #HEADER .gnavi ul li a:hover {
                opacity: .9; } }
  @media screen and (max-width: 768px) {
    #HEADER {
      padding: 0 12px !important; } }
  #HEADER a {
    color: #000; }
  #HEADER .logo a {
    background: url("../../../images/common/logo.png") no-repeat;
    background-size: 203px 34px;
    width: 203px;
    height: 34px;
    display: block;
    text-indent: -9999px; }
  #HEADER .gnavi {
    background-color: rgba(255, 255, 255, 0.85);
    padding: 0 0 0 52px; }
    @media screen and (max-width: 768px) {
      #HEADER .gnavi {
        padding: 0; } }
    #HEADER .gnavi ul > li a {
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      height: 80px; }
      @media screen and (max-width: 768px) {
        #HEADER .gnavi ul > li a {
          height: auto; } }
      #HEADER .gnavi ul > li a.link {
        font-size: 14px;
        font-weight: 700;
        position: relative;
        top: 0;
        left: 0; }
        #HEADER .gnavi ul > li a.link:before {
          content: '';
          width: 0;
          height: 5px;
          background: #4f76d3;
          position: absolute;
          left: 50%;
          bottom: 0;
          -webkit-transition: all 120ms 0ms ease-in-out;
          -moz-transition: all 120ms 0ms ease-in-out;
          -ms-transition: all 120ms 0ms ease-in-out;
          -o-transition: all 120ms 0ms ease-in-out;
          transition: all 120ms 0ms ease-in-out; }
          @media screen and (max-width: 768px) {
            #HEADER .gnavi ul > li a.link:before {
              content: none; } }
        #HEADER .gnavi ul > li a.link:hover:before {
          width: 67px;
          margin-left: -33.5px; }
      #HEADER .gnavi ul > li a.contact {
        font-size: 14px;
        width: 140px;
        background-color: #4a68d5;
        background-image: linear-gradient(to left, #4ab0d5 0%, #4a68d5 100%);
        color: #FFF;
        text-indent: -9999px; }
        @media screen and (max-width: 768px) {
          #HEADER .gnavi ul > li a.contact {
            width: 100%;
            background: rgba(255, 255, 255, 0.95);
            text-indent: 0;
            color: #000; } }
        #HEADER .gnavi ul > li a.contact span {
          text-indent: 0;
          background: url("../../../images/common/mail_icon.png") no-repeat top center;
          font-family: 'Open Sans', sans-serif;
          padding: 26px 0 0; }
          @media screen and (max-width: 768px) {
            #HEADER .gnavi ul > li a.contact span {
              display: none; } }
  #HEADER.active {
    -webkit-transition: all 120ms 0ms ease-in-out;
    -moz-transition: all 120ms 0ms ease-in-out;
    -ms-transition: all 120ms 0ms ease-in-out;
    -o-transition: all 120ms 0ms ease-in-out;
    transition: all 120ms 0ms ease-in-out;
    background: #FFF;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12); }
    #HEADER.active .logo a {
      background: url("../../../images/common/ft_logo.png") no-repeat top 2px left;
      background-size: contain; }

#FOOTER {
  /* PC STYLE
	----------------------------------------*/
  /* SP STYLE
	----------------------------------------*/
  /* CUSTUM STYLE
	----------------------------------------*/ }
  @media screen and (min-width: 769px) {
    #FOOTER {
      width: 100%;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      padding: 0 0 65px 0;
      background: #1b1b1b;
      z-index: 1; }
      #FOOTER .fnav {
        text-align: center;
        padding: 65px 0 0 0; }
        #FOOTER .fnav li {
          list-style: none;
          margin-top: 0;
          display: inline-block;
          margin-left: 10px; }
          #FOOTER .fnav li:after {
            content: "|";
            display: inline-block;
            margin-left: 10px;
            color: #555; }
          #FOOTER .fnav li:last-child:after {
            display: none; }
          #FOOTER .fnav li:first-child {
            margin-left: 0; }
      #FOOTER .copy {
        display: block;
        font-size: 10px;
        color: #FFF;
        padding: 20px 0 0 0;
        text-align: center; } }
  @media screen and (max-width: 768px) {
    #FOOTER {
      width: 100%;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      padding: 0 0 24px 0;
      background: #FFF;
      z-index: 1; }
      #FOOTER .fnav {
        text-align: center;
        padding: 0 0 0 0;
        border: #DDD 1px solid;
        border-radius: 6px;
        overflow: hidden; }
        #FOOTER .fnav li {
          list-style: none;
          margin-top: 12px;
          display: inline-block;
          margin-left: 12px; }
          #FOOTER .fnav li:first-child {
            margin-left: 0; }
        #FOOTER .fnav li {
          float: none;
          text-align: left;
          display: block;
          margin: 0;
          padding: 0;
          border-bottom: #DDD 1px solid; }
          #FOOTER .fnav li:last-child {
            border: none; }
          #FOOTER .fnav li a {
            background: #EEE;
            color: #000;
            display: block;
            padding: 12px 12px; }
            #FOOTER .fnav li a:hover {
              opacity: .9; }
      #FOOTER .copy {
        display: block;
        font-size: 10px;
        color: #000;
        padding: 0 0 0 0;
        text-align: center; } }
  #FOOTER a {
    color: #FFF; }
  #FOOTER .pageup {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    background: #313131;
    padding: 16px 0;
    text-align: center;
    color: #fff;
    display: block;
    -webkit-transition: all 200ms 0ms ease-in-out;
    -moz-transition: all 200ms 0ms ease-in-out;
    -ms-transition: all 200ms 0ms ease-in-out;
    -o-transition: all 200ms 0ms ease-in-out;
    transition: all 200ms 0ms ease-in-out; }
  @media screen and (max-width: 768px) {
    #FOOTER .display {
      padding: 0 12px; }
    #FOOTER .fnav {
      margin: 24px 0; } }

.firstview {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000;
  overflow: hidden;
  z-index: 0; }
  .firstview:before {
    content: '';
    background: rgba(0, 0, 0, 0.19);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
  .firstview .content {
    width: 100%;
    max-width: 1120px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 3;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
    transform: translate(-50%, 0); }
    @media screen and (max-width: 768px) {
      .firstview .content {
        max-width: 100%; } }
    .firstview .content h2 {
      font-family: 'Open Sans', sans-serif;
      font-size: 22px;
      font-weight: 700;
      color: #FFF;
      width: 100%;
      letter-spacing: 0.20em; }
      @media screen and (max-width: 768px) {
        .firstview .content h2 {
          padding: 0 12px;
          font-size: 18px; } }
      .firstview .content h2 figure {
        display: block;
        margin: 0 0 28px; }
        @media screen and (max-width: 768px) {
          .firstview .content h2 figure {
            margin: 0 0 14px; } }
        .firstview .content h2 figure img {
          max-width: 596px;
          height: auto; }
          @media screen and (max-width: 768px) {
            .firstview .content h2 figure img {
              max-width: 100%; } }
    .firstview .content .arrowWrap {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 130px; }
      .firstview .content .arrowWrap p {
        text-align: end;
        transform: rotate(90deg);
        color: #FFF;
        font-family: 'Open Sans', sans-serif;
        font-size: 9px;
        font-weight: 700; }
      .firstview .content .arrowWrap .arrow {
        width: 1px;
        height: 130px;
        margin: 20px auto 0;
        background-color: #FFF;
        position: relative;
        overflow: hidden; }
        .firstview .content .arrowWrap .arrow:before {
          content: '';
          width: 1px;
          height: 130px;
          margin: 20px auto 0;
          background-color: rgba(0, 0, 0, 0.55);
          position: absolute;
          top: -150px;
          left: 0;
          -webkit-animation: arrow 2.5s ease 0s infinite normal;
          animation: arrow 2.5s ease 0s infinite normal; }
  .firstview .hide {
    display: none; }

@keyframes arrow {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); }
  60% {
    -webkit-transform: translate3d(-50%, 100px, 0);
    transform: translate3d(-50%, 100px, 0); }
  100% {
    -webkit-transform: translate3d(-50%, 100px, 0);
    transform: translate3d(-50%, 100px, 0); } }

.INDEX.first {
  background: url("../../../images/index/back_02.png") no-repeat center bottom;
  background-size: cover; }
  .INDEX.first .center {
    font-weight: 700; }
  .INDEX.first .flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    margin: 42px 0 0;
    counter-reset: num; }
    @media screen and (max-width: 768px) {
      .INDEX.first .flex {
        margin: 24px 0 0; } }
    .INDEX.first .flex > li {
      list-style: none;
      width: 31%;
      background: #FFF;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
      @media screen and (max-width: 768px) {
        .INDEX.first .flex > li {
          width: 100%;
          margin: 0 0 4%; } }
      .INDEX.first .flex > li figure {
        position: relative; }
        .INDEX.first .flex > li figure:before {
          content: counter(num, decimal-leading-zero);
          counter-increment: num;
          font-family: 'Open Sans', sans-serif;
          color: #FFF;
          font-size: 24px;
          font-weight: 700;
          width: 56px;
          height: 56px;
          display: block;
          text-align: center;
          padding-top: 6px;
          background: #4f76d3;
          position: absolute;
          bottom: 0;
          left: 0; }
        .INDEX.first .flex > li figure img {
          max-width: 100%;
          height: auto; }
      .INDEX.first .flex > li dl {
        padding: 30px; }
        @media screen and (max-width: 768px) {
          .INDEX.first .flex > li dl {
            padding: 20px; } }
        .INDEX.first .flex > li dl dt {
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          border-bottom: 1px solid #DDD;
          padding: 0 0 8px;
          margin: 0 0 16px; }
          @media screen and (max-width: 768px) {
            .INDEX.first .flex > li dl dt {
              font-size: 18px; } }
          .INDEX.first .flex > li dl dt:before {
            content: attr(data-eng);
            color: #ddab00;
            font-family: 'Open Sans', sans-serif;
            font-size: 11px;
            font-weight: 700;
            display: block; }
            @media screen and (max-width: 768px) {
              .INDEX.first .flex > li dl dt:before {
                font-size: 10px; } }

.INDEX.second .center {
  font-weight: 700; }

.INDEX.second .flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  margin: 42px 0 0; }
  .INDEX.second .flex > li {
    width: 50%;
    min-height: 270px;
    position: relative; }
    .INDEX.second .flex > li:nth-child(1) {
      background: url("../../../images/index/photo_2_01.jpg") no-repeat center;
      background-size: cover; }
      .INDEX.second .flex > li:nth-child(1) dl dt {
        background: url("../../../images/index/icon_01.png") no-repeat top center; }
    .INDEX.second .flex > li:nth-child(2) {
      background: url("../../../images/index/photo_2_02.jpg") no-repeat center;
      background-size: cover; }
      .INDEX.second .flex > li:nth-child(2) dl dt {
        background: url("../../../images/index/icon_02.png") no-repeat top center; }
    .INDEX.second .flex > li:nth-child(3) {
      background: url("../../../images/index/photo_2_03.jpg") no-repeat center;
      background-size: cover; }
      .INDEX.second .flex > li:nth-child(3) dl dt {
        background: url("../../../images/index/icon_03.png") no-repeat top center; }
    .INDEX.second .flex > li:nth-child(4) {
      background: url("../../../images/index/photo_2_04.jpg") no-repeat center;
      background-size: cover; }
      .INDEX.second .flex > li:nth-child(4) dl dt {
        background: url("../../../images/index/icon_04.png") no-repeat top center; }
    @media screen and (max-width: 768px) {
      .INDEX.second .flex > li {
        width: 100%;
        min-height: inherit;
        padding: 20px;
        margin: 0 0 4%; } }
    .INDEX.second .flex > li dl {
      width: 52%;
      background: #FFF;
      padding: 40px;
      box-shadow: 0px 3px 30px 3px rgba(0, 0, 0, 0.25);
      position: absolute;
      right: 32px;
      bottom: -50px;
      z-index: 2; }
      @media screen and (max-width: 768px) {
        .INDEX.second .flex > li dl {
          width: 95%;
          margin: 0 auto;
          padding: 20px;
          position: static;
          background: rgba(255, 255, 255, 0.85); } }
      .INDEX.second .flex > li dl dt {
        color: #4f76d3;
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        padding: 38px 0 8px; }
        @media screen and (max-width: 768px) {
          .INDEX.second .flex > li dl dt {
            font-size: 18px;
            padding-top: 30px; } }
      .INDEX.second .flex > li dl dd {
        font-size: 16px; }
        @media screen and (max-width: 768px) {
          .INDEX.second .flex > li dl dd {
            font-size: 13px; } }

.INDEX.second .btnArea {
  margin-top: 92px; }
  @media screen and (max-width: 768px) {
    .INDEX.second .btnArea {
      margin-top: 24px; } }

.INDEX.third {
  overflow: hidden;
  position: relative;
  /*
		.swiper-button-next,.swiper-button-prev {
			width: 108px;
			height: 108px;
			margin-top: -54px;
		}
		.swiper-button-next {
			background: url("../../../images/index/next.png") no-repeat center;
			background-size: 108px;
		}
		.swiper-button-prev {
			background: url("../../../images/index/prev.png") no-repeat center;
			background-size: 108px;
		}
		*/ }
  .INDEX.third:before {
    content: "";
    width: 100%;
    height: 300px;
    background: #4f76d3;
    position: absolute;
    bottom: 0;
    left: 0; }
    @media screen and (max-width: 768px) {
      .INDEX.third:before {
        height: 165px; } }
  .INDEX.third ul > li {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    background: #FFF; }
    @media screen and (max-width: 768px) {
      .INDEX.third ul > li {
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.15); } }
    .INDEX.third ul > li figure img {
      max-width: 100%;
      height: auto; }
    .INDEX.third ul > li figcaption {
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      padding: 15px 0; }
  .INDEX.third .swiper-container {
    width: 100%;
    overflow: visible; }
  .INDEX.third .swiper-pagination {
    bottom: -40px; }
    @media screen and (max-width: 768px) {
      .INDEX.third .swiper-pagination {
        display: none; } }
  .INDEX.third .swiper-pagination-bullet {
    background: #FFF;
    border: 1px solid #FFF; }

.INDEX.four .flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between; }
  .INDEX.four .flex .news, .INDEX.four .flex .env {
    width: 50%; }
    @media screen and (max-width: 768px) {
      .INDEX.four .flex .news, .INDEX.four .flex .env {
        width: 100%; } }
    .INDEX.four .flex .news .block, .INDEX.four .flex .env .block {
      width: calc(1120px / 2);
      padding: 42px 0;
      position: relative; }
      @media screen and (max-width: 768px) {
        .INDEX.four .flex .news .block, .INDEX.four .flex .env .block {
          width: 100%;
          padding: 32px 12px !important; } }
  .INDEX.four .flex .env {
    border-bottom: solid 1px #a1b6e7; }
  .INDEX.four .flex .news {
    background: #325bbc; }
    .INDEX.four .flex .news .block {
      margin-left: auto;
      padding-right: 42px; }
      @media screen and (max-width: 768px) {
        .INDEX.four .flex .news .block {
          margin: 0 auto; } }
      .INDEX.four .flex .news .block .btnArea {
        margin: 48px 0 0; }
    .INDEX.four .flex .news .news_list {
      background: #fff;
      max-height: 160px;
      overflow-y: scroll;
      padding: 18px 30px; }
      @media screen and (max-width: 768px) {
        .INDEX.four .flex .news .news_list {
          padding: 20px; } }
      .INDEX.four .flex .news .news_list > li {
        list-style: none;
        padding: 12px 0;
        border-bottom: 1px solid #e5e5e5; }
        .INDEX.four .flex .news .news_list > li a {
          display: block;
          color: #000; }
          .INDEX.four .flex .news .news_list > li a time {
            display: inline-block;
            background: #4f76d3;
            font-size: 11px;
            font-weight: 700;
            color: #fff;
            padding: 5px 10px;
            margin-right: 20px; }
            @media screen and (max-width: 768px) {
              .INDEX.four .flex .news .news_list > li a time {
                display: block;
                width: 25%;
                text-align: center;
                padding: 2px 0;
                margin-bottom: 5px; } }
    .INDEX.four .flex .news .more {
      background: url("../../../images/common/news_icon.png") no-repeat 46px center #213d82;
      background-size: 26px 17px;
      font-size: 16px;
      font-weight: 700;
      display: inline-block;
      text-align: center;
      color: #FFF;
      padding: 12px 72px 12px 98px; }
      @media screen and (max-width: 768px) {
        .INDEX.four .flex .news .more {
          display: block;
          padding: 12px; } }
  .INDEX.four .flex .env {
    background: url("../../../images/index/back_01.jpg") no-repeat center;
    background-size: cover;
    position: relative; }
    .INDEX.four .flex .env:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      position: absolute;
      top: 0;
      left: 0; }
    .INDEX.four .flex .env h3.index {
      font-size: 14px; }
      @media screen and (max-width: 768px) {
        .INDEX.four .flex .env h3.index {
          font-size: 13px; } }
      .INDEX.four .flex .env h3.index:before {
        font-size: 26px; }
        @media screen and (max-width: 768px) {
          .INDEX.four .flex .env h3.index:before {
            font-size: 22px; } }
    .INDEX.four .flex .env .block {
      padding-left: 42px; }
      @media screen and (max-width: 768px) {
        .INDEX.four .flex .env .block {
          padding-left: auto; } }

.INDEX.five .info {
  background: #FFF;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center; }
  .INDEX.five .info .plofile {
    width: 50%;
    max-width: calc(1120px / 2);
    margin-left: auto;
    padding-right: 5%; }
    @media screen and (max-width: 768px) {
      .INDEX.five .info .plofile {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
        padding: 32px 12px; } }
    .INDEX.five .info .plofile dt {
      padding: 0 0 24px; }
      .INDEX.five .info .plofile dt span {
        display: block;
        color: #4f76d3;
        font-family: 'Open Sans', sans-serif;
        padding: 0 0 12px;
        font-size: 12px;
        font-weight: 700; }
      .INDEX.five .info .plofile dt figure img {
        width: auto;
        height: 40px; }
    .INDEX.five .info .plofile dd:first-of-type {
      border-bottom: 1px solid #DDD;
      padding: 0 0 12px;
      margin: 0 0 12px; }
    .INDEX.five .info .plofile dd span {
      display: inline-block;
      font-family: 'Open Sans', sans-serif; }
      .INDEX.five .info .plofile dd span:first-of-type {
        margin-right: 10%; }
      .INDEX.five .info .plofile dd span i {
        display: inline-block;
        font-size: 13px;
        background: #4f76d3;
        color: #FFF;
        padding: 3px 20px;
        margin-right: 14px; }
      .INDEX.five .info .plofile dd span a {
        color: #4f76d3;
        text-decoration: underline; }
        @media screen and (min-width: 769px) {
          .INDEX.five .info .plofile dd span a {
            pointer-events: none;
            color: #000;
            text-decoration: none; } }
  .INDEX.five .info .mapArea {
    width: 50%;
    height: 350px; }
    @media screen and (max-width: 768px) {
      .INDEX.five .info .mapArea {
        width: 100%;
        height: 200px; }
        .INDEX.five .info .mapArea iframe {
          width: 100%;
          height: 200px; } }

.ADVANTAGE.first .oneClum {
  margin: 42px 0 0;
  counter-reset: num; }
  @media screen and (max-width: 768px) {
    .ADVANTAGE.first .oneClum {
      margin: 24px 0 0; } }
  .ADVANTAGE.first .oneClum > li {
    list-style: none;
    padding: 50px 0;
    margin: 0 0 48px; }
    .ADVANTAGE.first .oneClum > li:nth-child(1) {
      background: url("../../../images/advantage/back_01.png") no-repeat center;
      background-size: cover; }
    .ADVANTAGE.first .oneClum > li:nth-child(2) {
      background: url("../../../images/advantage/back_02.png") no-repeat center;
      background-size: cover; }
    .ADVANTAGE.first .oneClum > li:nth-child(3) {
      background: url("../../../images/advantage/back_03.png") no-repeat center;
      background-size: cover; }
    .ADVANTAGE.first .oneClum > li:nth-child(odd) dl {
      margin-left: auto; }
    .ADVANTAGE.first .oneClum > li:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 768px) {
      .ADVANTAGE.first .oneClum > li {
        padding: 20px;
        margin: 0 0 4%; } }
    .ADVANTAGE.first .oneClum > li dl {
      width: 74%;
      padding: 50px;
      background: #FFF;
      box-shadow: 0px 0px 26px 0.55px rgba(0, 0, 0, 0.23); }
      @media screen and (max-width: 768px) {
        .ADVANTAGE.first .oneClum > li dl {
          width: 100%;
          padding: 20px;
          background: rgba(255, 255, 255, 0.75); } }
      .ADVANTAGE.first .oneClum > li dl dt {
        font-size: 22px;
        font-weight: 700;
        border-bottom: 1px solid #DDD;
        padding: 0 0 12px;
        margin: 0 0 16px; }
        @media screen and (max-width: 768px) {
          .ADVANTAGE.first .oneClum > li dl dt {
            font-size: 18px; } }
        .ADVANTAGE.first .oneClum > li dl dt:before {
          counter-increment: num;
          content: counter(num);
          font-family: 'Open Sans', sans-serif;
          font-size: 17px;
          font-weight: 700;
          color: #FFF;
          background: #4f76d3;
          display: inline-block;
          width: 30px;
          height: 30px;
          text-align: center;
          margin-right: 16px; }

.BUSINESS.first .oneClum {
  margin: 42px 0 0; }
  @media screen and (max-width: 768px) {
    .BUSINESS.first .oneClum {
      margin: 24px 0 0; } }
  .BUSINESS.first .oneClum > li {
    list-style: none;
    display: -webkit-flex;
    display: -moz-flex;
    display: -o-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-flow: row wrap;
    -webkit-flex-flow: row wrap;
    -o-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-justify-content: space-between;
    -o-justify-content: space-between;
    -ms-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    box-shadow: 0px 0px 34px 0.7px rgba(0, 0, 0, 0.11);
    margin: 0 0 32px; }
    .BUSINESS.first .oneClum > li:nth-child(1) dt {
      background: url("../../../images/business/icon_01.png") no-repeat left 4px;
      background-size: 30px; }
      @media screen and (max-width: 768px) {
        .BUSINESS.first .oneClum > li:nth-child(1) dt {
          background-position: left 1px; } }
    .BUSINESS.first .oneClum > li:nth-child(2) dt {
      background: url("../../../images/business/icon_02.png") no-repeat left 4px;
      background-size: 30px; }
      @media screen and (max-width: 768px) {
        .BUSINESS.first .oneClum > li:nth-child(2) dt {
          background-position: left 1px; } }
    .BUSINESS.first .oneClum > li:nth-child(3) dt {
      background: url("../../../images/business/icon_03.png") no-repeat left 4px;
      background-size: 30px; }
      @media screen and (max-width: 768px) {
        .BUSINESS.first .oneClum > li:nth-child(3) dt {
          background-position: left 1px; } }
    .BUSINESS.first .oneClum > li:nth-child(4) dt {
      background: url("../../../images/business/icon_04.png") no-repeat left 4px;
      background-size: 30px; }
      @media screen and (max-width: 768px) {
        .BUSINESS.first .oneClum > li:nth-child(4) dt {
          background-position: left 1px; } }
    @media screen and (min-width: 769px) {
      .BUSINESS.first .oneClum > li:nth-child(even) figure {
        order: 2; }
      .BUSINESS.first .oneClum > li:nth-child(even) dl {
        order: 1; } }
    .BUSINESS.first .oneClum > li:last-child {
      margin-bottom: 0; }
    @media screen and (max-width: 768px) {
      .BUSINESS.first .oneClum > li {
        margin: 0 0 4%; } }
    .BUSINESS.first .oneClum > li figure {
      width: 35%; }
      @media screen and (max-width: 768px) {
        .BUSINESS.first .oneClum > li figure {
          width: 100%; } }
      .BUSINESS.first .oneClum > li figure img {
        max-width: 100%;
        height: auto; }
    .BUSINESS.first .oneClum > li dl {
      width: 65%;
      padding: 45px; }
      @media screen and (max-width: 768px) {
        .BUSINESS.first .oneClum > li dl {
          width: 100%;
          padding: 20px; } }
      .BUSINESS.first .oneClum > li dl dt {
        font-size: 22px;
        font-weight: 700;
        padding: 0 0 6px 40px;
        margin: 0 0 12px;
        border-bottom: 1px solid #DDD; }
        @media screen and (max-width: 768px) {
          .BUSINESS.first .oneClum > li dl dt {
            font-size: 18px; } }

.BUSINESS.third {
  background: rgba(79, 118, 211, 0.1); }
  .BUSINESS.third:before {
    content: none !important; }
  .BUSINESS.third .swiper-pagination-bullet {
    background: #4f76d3;
    border: 1px solid #4f76d3; }

.ENVIRONMENTAL.first .check {
  background: #f5f5f5;
  width: 70%;
  margin: 42px auto;
  padding: 45px; }
  @media screen and (max-width: 768px) {
    .ENVIRONMENTAL.first .check {
      width: 100%;
      padding: 20px;
      margin: 24px 0; } }
  .ENVIRONMENTAL.first .check > li {
    list-style: none;
    border-bottom: 1px solid #FFF;
    background: url("../../../images/environmental/icon_check.png") no-repeat left 0;
    background-size: 24px;
    padding: 0 0 8px 38px;
    margin: 0 0 12px;
    font-size: 16px; }
    .ENVIRONMENTAL.first .check > li:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none; }
    @media screen and (max-width: 768px) {
      .ENVIRONMENTAL.first .check > li {
        font-size: 13px; } }

.ENVIRONMENTAL.first .oneClum {
  counter-reset: num; }
  .ENVIRONMENTAL.first .oneClum > li {
    list-style: none;
    padding: 45px;
    margin: 0 0 32px;
    min-height: 180px;
    box-shadow: 0px 0px 34px 0.7px rgba(0, 0, 0, 0.11);
    position: relative; }
    @media screen and (max-width: 768px) {
      .ENVIRONMENTAL.first .oneClum > li {
        padding: 20px;
        min-height: inherit;
        margin: 0 0 4%; } }
    .ENVIRONMENTAL.first .oneClum > li:before {
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.55);
      position: absolute;
      top: 0;
      left: 0; }
    .ENVIRONMENTAL.first .oneClum > li:nth-child(1) {
      background: url("../../../images/environmental/back_01.png") no-repeat left center;
      background-size: contain; }
      @media screen and (max-width: 768px) {
        .ENVIRONMENTAL.first .oneClum > li:nth-child(1) {
          background-size: cover; } }
    .ENVIRONMENTAL.first .oneClum > li:nth-child(2) {
      background: url("../../../images/environmental/back_02.png") no-repeat left center;
      background-size: contain; }
      @media screen and (max-width: 768px) {
        .ENVIRONMENTAL.first .oneClum > li:nth-child(2) {
          background-size: cover; } }
    .ENVIRONMENTAL.first .oneClum > li:nth-child(3) {
      background: url("../../../images/environmental/back_03.png") no-repeat left center;
      background-size: contain; }
      @media screen and (max-width: 768px) {
        .ENVIRONMENTAL.first .oneClum > li:nth-child(3) {
          background-size: cover; } }
    .ENVIRONMENTAL.first .oneClum > li:last-child {
      margin-bottom: 0; }
    .ENVIRONMENTAL.first .oneClum > li dl {
      position: relative;
      display: -webkit-flex;
      display: -moz-flex;
      display: -o-flex;
      display: -ms-flex;
      display: flex;
      -moz-flex-flow: row wrap;
      -webkit-flex-flow: row wrap;
      -o-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-justify-content: space-between;
      -o-justify-content: space-between;
      -ms-justify-content: space-between;
      -moz-justify-content: space-between;
      justify-content: space-between;
      align-items: center; }
      .ENVIRONMENTAL.first .oneClum > li dl dt {
        width: 42%;
        font-size: 22px;
        font-weight: 700;
        position: relative; }
        @media screen and (max-width: 768px) {
          .ENVIRONMENTAL.first .oneClum > li dl dt {
            width: 100%;
            font-size: 16px;
            padding: 0 0 8px; } }
        .ENVIRONMENTAL.first .oneClum > li dl dt:before {
          counter-increment: num;
          content: "INITIATIVES " counter(num, decimal-leading-zero);
          display: block;
          color: #4f76d3;
          font-family: 'Open Sans', sans-serif;
          font-size: 12px;
          font-weight: 700; }
      .ENVIRONMENTAL.first .oneClum > li dl dd {
        width: 50%; }
        @media screen and (max-width: 768px) {
          .ENVIRONMENTAL.first .oneClum > li dl dd {
            width: 100%; } }

@media screen and (min-width: 769px) {
  .CONSTRUCTION.first .locals li {
    list-style: none;
    width: 18.4%;
    float: left;
    margin: 0 2% 2% 0; }
    .CONSTRUCTION.first .locals li:nth-child(5n+1) {
      clear: both; }
    .CONSTRUCTION.first .locals li:nth-child(5n+5) {
      margin-right: 0; }
    .CONSTRUCTION.first .locals li:nth-last-child(-n+5) {
      margin-bottom: 0; } }

@media screen and (max-width: 768px) {
  .CONSTRUCTION.first .locals li {
    margin: 0 0 16px; }
    .CONSTRUCTION.first .locals li:last-child {
      margin: 0; } }

.CONSTRUCTION.first .locals li a {
  display: block;
  text-align: center;
  padding: 8px 0;
  color: #000;
  cursor: pointer;
  font-size: 15px;
  border-radius: 6px;
  background: rgba(79, 118, 211, 0.1); }
  .CONSTRUCTION.first .locals li a:hover {
    background: #f2f2f2; }

.CONSTRUCTION.first #props {
  margin: 64px 0 0; }
  @media screen and (max-width: 768px) {
    .CONSTRUCTION.first #props {
      margin: 32px 0 0; } }
  .CONSTRUCTION.first #props .tableProp {
    border: #DDD 1px solid;
    border-collapse: collapse;
    width: 100%; }
    .CONSTRUCTION.first #props .tableProp th, .CONSTRUCTION.first #props .tableProp td {
      border: #DDD 1px solid;
      padding: 8px 16px;
      font-size: 15px; }
      @media screen and (max-width: 768px) {
        .CONSTRUCTION.first #props .tableProp th, .CONSTRUCTION.first #props .tableProp td {
          padding: 8px 15px;
          font-size: 12px; } }
    .CONSTRUCTION.first #props .tableProp tr:first-child th, .CONSTRUCTION.first #props .tableProp tr:first-child td {
      background: #f2f2f2; }
    .CONSTRUCTION.first #props .tableProp tr td:nth-child(2) {
      width: 25%; }
      @media screen and (max-width: 768px) {
        .CONSTRUCTION.first #props .tableProp tr td:nth-child(2) {
          width: 24%; } }
    .CONSTRUCTION.first #props .tableProp tr td:nth-child(3) {
      width: 20%; }
      @media screen and (max-width: 768px) {
        .CONSTRUCTION.first #props .tableProp tr td:nth-child(3) {
          width: 16%; } }

@media screen and (min-width: 769px) {
  .CONSTRUCTION.second .Clum > li {
    list-style: none;
    width: 32%;
    float: left;
    margin: 0 2% 2% 0; }
    .CONSTRUCTION.second .Clum > li:nth-child(3n+1) {
      clear: both; }
    .CONSTRUCTION.second .Clum > li:nth-child(3n+3) {
      margin-right: 0; }
    .CONSTRUCTION.second .Clum > li:nth-last-child(-n+3) {
      margin-bottom: 0; } }

@media screen and (max-width: 768px) {
  .CONSTRUCTION.second .Clum > li {
    list-style: none;
    margin: 0 0 2%; } }

.CONSTRUCTION.second .Clum > li figure img {
  max-width: 100%;
  height: auto; }

.COMPANY.first .tableStyle td .two {
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  justify-content: flex-start; }
  .COMPANY.first .tableStyle td .two dt {
    width: 14%; }
    @media screen and (max-width: 768px) {
      .COMPANY.first .tableStyle td .two dt {
        width: 35%; } }
  .COMPANY.first .tableStyle td .two dd {
    width: 86%; }
    @media screen and (max-width: 768px) {
      .COMPANY.first .tableStyle td .two dd {
        width: 65%; } }

.RECRUIT.first p.center {
  margin: 0 0 32px;
  font-size: 18px;
  font-weight: 700; }
  @media screen and (max-width: 768px) {
    .RECRUIT.first p.center {
      margin: 0 0 24px; } }

.CONTACT.first .txtC {
  text-align: center; }

.CONTACT.first .formStyle {
  width: 100%;
  border-collapse: collapse; }
  .CONTACT.first .formStyle th {
    border-bottom: 1px solid #DDD;
    padding: 25px 0;
    vertical-align: middle;
    text-align: left;
    width: 30%;
    padding-right: 40px; }
    .CONTACT.first .formStyle th:before {
      content: "任意項目";
      background: #bbbbbb;
      color: #ffffff;
      padding: 2px 10px;
      float: right; }
    .CONTACT.first .formStyle th.hiss:before {
      content: "必須項目";
      background: #D02; }
    @media screen and (max-width: 768px) {
      .CONTACT.first .formStyle th {
        display: block;
        width: 100%;
        padding: 12px 0; } }
  .CONTACT.first .formStyle td {
    border-bottom: 1px solid #DDD;
    padding: 25px 0;
    background: #ffffff; }
    @media screen and (max-width: 768px) {
      .CONTACT.first .formStyle td {
        display: block;
        width: 100%;
        padding: 12px 0; } }
    .CONTACT.first .formStyle td label {
      display: inline-block;
      margin-right: 5px; }
    .CONTACT.first .formStyle td .mt {
      display: block;
      margin-top: 20px; }

.CONTACT input[type=text],
.CONTACT input[type=tel],
.CONTACT input[type=email],
.CONTACT textarea, .CONTACT select {
  font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.024em;
  font-size: 15px;
  background: #f5f5f5;
  width: 100%;
  border: none;
  padding: 5px 10px;
  line-height: 1.8; }
  @media screen and (max-width: 768px) {
    .CONTACT input[type=text],
    .CONTACT input[type=tel],
    .CONTACT input[type=email],
    .CONTACT textarea, .CONTACT select {
      display: block;
      margin: 0 0 5px;
      width: 100% !important;
      background: none;
      border: 1px solid #DDD; } }
  .CONTACT input[type=text].s,
  .CONTACT input[type=tel].s,
  .CONTACT input[type=email].s,
  .CONTACT textarea.s, .CONTACT select.s {
    width: 25%; }
  .CONTACT input[type=text].m,
  .CONTACT input[type=tel].m,
  .CONTACT input[type=email].m,
  .CONTACT textarea.m, .CONTACT select.m {
    width: 50%; }

.CONTACT input[type="submit"], .CONTACT input[type="reset"] {
  border: none;
  background-color: #4a68d5;
  background-image: linear-gradient(to left, #4ab0d5 0%, #4a68d5 100%);
  color: #ffffff;
  font-family: "Yu Gothic","游ゴシック",YuGothic,"Hiragino Kaku Gothic Pro","メイリオ", Meiryo,"MS Pゴシック", Helvetica, Arial, Verdana, sans-serif;
  font-weight: 500;
  -webkit-font-feature-settings: "palt";
  font-feature-settings: "palt";
  letter-spacing: 0.024em;
  font-size: 18px;
  display: inline-block;
  padding: 10px 0;
  margin: 0 1%;
  width: 20%;
  cursor: pointer; }
  .CONTACT input[type="submit"]:hover, .CONTACT input[type="reset"]:hover {
    background: #6386d8; }
  @media screen and (max-width: 768px) {
    .CONTACT input[type="submit"], .CONTACT input[type="reset"] {
      display: block;
      padding: 12px 0;
      margin: 0 0 10px;
      font-size: 17px;
      font-weight: 700;
      width: 100%;
      text-align: center; } }

.CONTACT input[type="reset"] {
  background: #c4c4c4; }
  .CONTACT input[type="reset"]:hover {
    background: #d0d0d0; }

.PRIVACY.first p {
  margin: 0 0 32px; }
  @media screen and (max-width: 768px) {
    .PRIVACY.first p {
      margin: 0 0 24px; } }

.PRIVACY.first dl {
  border: 10px solid rgba(79, 118, 211, 0.1);
  padding: 30px;
  margin: 0 0 24px; }
  .PRIVACY.first dl:last-of-type {
    margin-bottom: 0; }
  @media screen and (max-width: 768px) {
    .PRIVACY.first dl {
      padding: 20px;
      margin: 0 0 4%;
      border-width: 5px; } }
  .PRIVACY.first dl dt {
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 8px;
    margin: 0 0 12px;
    border-bottom: 1px solid #DDD;
    color: #4f76d3; }
    @media screen and (max-width: 768px) {
      .PRIVACY.first dl dt {
        font-size: 15px;
        line-height: 1.6; } }

.PRIVACY.first .disc {
  margin: 12px 0 0; }
  .PRIVACY.first .disc > li {
    list-style: disc outside;
    margin-left: 20px; }

@media screen and (min-width: 769px) {
  .SITEMAP.first .fourClum > li {
    list-style: none;
    width: 23.5%;
    float: left;
    margin: 0 2% 2% 0; }
    .SITEMAP.first .fourClum > li:nth-child(4n+1) {
      clear: both; }
    .SITEMAP.first .fourClum > li:nth-child(4n+4) {
      margin-right: 0; }
    .SITEMAP.first .fourClum > li:nth-last-child(-n+4) {
      margin-bottom: 0; } }

@media screen and (max-width: 768px) {
  .SITEMAP.first .fourClum > li {
    list-style: none;
    margin: 0 0 2%; } }

.SITEMAP.first .fourClum > li a {
  display: block;
  background-color: #4a68d5;
  background-image: linear-gradient(to left, #4ab0d5 0%, #4a68d5 100%);
  color: #FFF;
  font-size: 18px;
  padding: 12px;
  text-align: center;
  -webkit-transition: all 100ms 0ms ease-in-out;
  -moz-transition: all 100ms 0ms ease-in-out;
  -ms-transition: all 100ms 0ms ease-in-out;
  -o-transition: all 100ms 0ms ease-in-out;
  transition: all 100ms 0ms ease-in-out; }
  .SITEMAP.first .fourClum > li a:hover {
    background: #4f76d3; }
  @media screen and (max-width: 768px) {
    .SITEMAP.first .fourClum > li a {
      font-size: 13px; } }

.NEWS a {
  color: #000; }

.NEWS img {
  margin: 0 0 24px; }

.NEWS .date_right {
  color: #4f76d3;
  font-weight: 700;
  position: absolute;
  bottom: 6px;
  right: 0; }
  @media screen and (max-width: 768px) {
    .NEWS .date_right {
      position: relative;
      top: 0;
      right: auto;
      left: 0;
      margin: 0 0 8px; } }

@media screen and (max-width: 768px) {
  .NEWS .post {
    font-size: 14px; } }

.NEWS .list li {
  list-style: none;
  margin: 0 0 15px; }
  .NEWS .list li:last-child {
    margin: 0; }
  .NEWS .list li a {
    display: block;
    font-size: 15px;
    background: url(../images/common/glay_arrow_2x.png) no-repeat right 15px center;
    border: #DDD 1px solid;
    padding: 15px;
    padding-right: 32px; }
    .NEWS .list li a:hover {
      background-color: #f5f5f5; }
    .NEWS .list li a .date {
      padding: 4px 0;
      width: 100px;
      display: inline-block;
      margin: 0 16px 0 0;
      background: #4f76d3;
      color: #FFF;
      text-align: center;
      font-size: 12px; }
      @media screen and (max-width: 768px) {
        .NEWS .list li a .date {
          width: 80%;
          display: block;
          margin: 0 auto 12px; } }

.NEWS .back {
  line-height: 5;
  position: relative;
  padding: 0 0 0 30px; }

.NEWS .main figure {
  margin: 24px 0 0; }

.NEWS div.title {
  position: relative; }

#WRAPPER {
  position: relative;
  top: 80px;
  left: 0;
  z-index: 1;
  background: #FFF; }
  @media screen and (max-width: 768px) {
    #WRAPPER {
      top: 60px; } }

#FOOTER {
  position: relative;
  top: 80px; }
  @media screen and (max-width: 768px) {
    #FOOTER {
      top: 60px; } }

.motion-animate {
  opacity: 0;
  transform: translateY(-40px);
  transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .motion-animate.active {
    transform: translateY(0);
    opacity: 1; }

.MODULE.wrap {
  padding: 72px 0; }
  @media screen and (max-width: 768px) {
    .MODULE.wrap {
      padding: 32px 12px; } }
  .MODULE.wrap.no-top {
    padding-top: 0; }

.MODULE h3 {
  margin: 0 0 34px; }
  @media screen and (max-width: 768px) {
    .MODULE h3 {
      margin: 0 0 24px; } }
  .MODULE h3.center {
    text-align: center;
    background: url("../../images/common/h3_01.png") no-repeat bottom center;
    background-size: 36px 3px;
    font-size: 30px;
    font-weight: 700;
    padding: 0 0 15px; }
    @media screen and (max-width: 768px) {
      .MODULE h3.center {
        font-size: 23px;
        padding-bottom: 8px; } }
  .MODULE h3.index {
    text-align: center;
    background: url("../../images/common/h3_02.png") no-repeat top center;
    font-size: 16px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      .MODULE h3.index {
        font-size: 12px; } }
    .MODULE h3.index:before {
      content: attr(data-eng);
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 42px;
      font-weight: 800;
      padding: 19px 0 10px; }
      @media screen and (max-width: 768px) {
        .MODULE h3.index:before {
          font-size: 26px;
          padding: 12px 0 0; } }

.MODULE h4 {
  margin: 0 0 24px; }
  @media screen and (max-width: 768px) {
    .MODULE h4 {
      margin: 0 0 18px; } }
  .MODULE h4.border {
    border-bottom: 1px solid #DDD;
    font-size: 24px;
    font-weight: 700;
    padding: 0 0 0 16px;
    position: relative; }
    @media screen and (max-width: 768px) {
      .MODULE h4.border {
        font-size: 20px; } }
    .MODULE h4.border:before {
      content: "";
      width: 4px;
      height: 70%;
      background: #4f76d3;
      position: absolute;
      top: 8px;
      left: 0; }

.MODULE .btnArea {
  margin: 32px 0 0; }
  @media screen and (max-width: 768px) {
    .MODULE .btnArea {
      margin: 24px 0 0; } }
  .MODULE .btnArea a {
    display: inline-block;
    font-size: 16px;
    padding: 12px 82px;
    font-size: 16px;
    font-weight: 700; }
    @media screen and (max-width: 768px) {
      .MODULE .btnArea a {
        display: block;
        padding: 12px 0;
        text-align: center; } }
    .MODULE .btnArea a.grad {
      background-color: #4a68d5;
      background-image: linear-gradient(to left, #4ab0d5 0%, #4a68d5 100%);
      color: #FFF;
      padding: 0; }
    .MODULE .btnArea a .arw {
      display: block;
      background: url("../../images/common/arw_01_2x.png") no-repeat right 18px center;
      background-size: 9px 16px;
      padding: 12px 82px; }
      .MODULE .btnArea a .arw:hover {
        -webkit-transition: all 200ms 0ms ease-in-out;
        -moz-transition: all 200ms 0ms ease-in-out;
        -ms-transition: all 200ms 0ms ease-in-out;
        -o-transition: all 200ms 0ms ease-in-out;
        transition: all 200ms 0ms ease-in-out;
        background-position: right 15px center; }

.MODULE .tableStyle {
  width: 100%;
  border: 1px solid #DDD;
  border-collapse: collapse; }
  .MODULE .tableStyle th {
    border: 1px solid #DDD;
    padding: 15px;
    vertical-align: middle;
    text-align: left;
    background: #f5f5f5; }
  .MODULE .tableStyle td {
    border: 1px solid #DDD;
    padding: 15px;
    background: #ffffff; }
  @media screen and (max-width: 768px) {
    .MODULE .tableStyle th, .MODULE .tableStyle td {
      padding: 10px; }
    .MODULE .tableStyle.block {
      border-bottom: none; }
      .MODULE .tableStyle.block th, .MODULE .tableStyle.block td {
        display: block;
        border: none;
        border-bottom: 1px solid #DDD; }
      .MODULE .tableStyle.block th {
        text-align: center; } }

.MODULE .center {
  text-align: center; }
  @media screen and (max-width: 768px) {
    .MODULE .center {
      text-align: left; } }
  .MODULE .center i {
    display: inline-block;
    color: #4f76d3;
    text-decoration: underline; }

#SecondView {
  width: 100%;
  height: 280px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -o-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  -o-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  -moz-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  background: url("../../images/common/secondView.jpg") no-repeat center;
  background-size: cover;
  position: relative; }
  @media screen and (max-width: 768px) {
    #SecondView {
      height: 180px; } }
  #SecondView:before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0; }
  #SecondView h2 {
    font-size: 12px;
    font-weight: 700;
    color: #FFF;
    text-align: center;
    position: relative; }
    #SecondView h2:before {
      content: attr(data-eng);
      display: block;
      font-family: 'Open Sans', sans-serif;
      font-size: 42px;
      font-weight: 700; }
      @media screen and (max-width: 768px) {
        #SecondView h2:before {
          font-size: 28px; } }
  #SecondView nav {
    width: 100%;
    position: absolute;
    bottom: 12px;
    left: 0; }
    @media screen and (max-width: 768px) {
      #SecondView nav ol {
        padding: 0 12px; } }
    #SecondView nav ol li {
      list-style: none;
      color: #FFF;
      font-size: 12px;
      background: url(../../images/common/arrow_2x.png) no-repeat right center;
      background-size: 4px;
      padding: 0 16px 0 0;
      margin: 0 12px 0 0;
      float: left; }
      @media screen and (max-width: 768px) {
        #SecondView nav ol li {
          font-size: 10px; } }
      #SecondView nav ol li:last-child {
        margin: 0;
        padding: 0;
        background: none; }
      #SecondView nav ol li a {
        color: #FFF; }
        #SecondView nav ol li a:hover {
          opacity: .88; }
