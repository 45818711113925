@charset "utf-8";

@mixin headerStyle(
	$header-width:auto,
	$header-height:auto,
	$header-x:0,
	$header-y:0,
	$header-top-padding:0,
	$header-right-padding:0,
	$header-bottom-padding:0,
	$header-left-padding:0,
	$header-attachment:relative,
	$header-nav-position:none,
	//$header-background:#FFF,
	$header-zindex:1,
	
	$logo-class:'.header-logo',
	$logo-width:auto,
	$logo-height:auto,
	$logo-position:none,
	$logo-top-margin:0,
	$logo-right-margin:0,
	$logo-bottom-margin:0,
	$logo-left-margin:0,
	
	$nav-class:'.header-nav',
	$nav-position:none,
	$nav-top-margin:0,
	$nav-right-margin:0,
	$nav-bottom-margin:0,
	$nav-left-margin:0,
	
	$nav-list:none,
	$nav-list-side:0,
	$nav-list-top:0,
	$responsive:'false',
	
	$responsive-color:$normal,
	$responsive-background:#FFF,
	$responsive-border:#EEE,
	$responsive-shadow:false
){
	width:$header-width;
	height:$header-height;
	position:$header-attachment;
	top:$header-x;
	left:$header-y;
	padding:$header-top-padding $header-right-padding $header-bottom-padding $header-right-padding;
	//background:$header-background;
	z-index:$header-zindex;
	#{$logo-class}{
		float:$logo-position;
		margin:$logo-top-margin $logo-right-margin $logo-bottom-margin $logo-left-margin;
		img{
			width:$logo-width;
			height:$logo-height;
		}
	}
	#{$nav-class}{
		float:$nav-position;
		margin:$nav-top-margin $nav-right-margin $nav-bottom-margin $nav-left-margin;
		li{
			list-style:none;
			margin-top:$nav-list-top;
			float:$nav-list;
			@if $nav-list == left {
				margin-left:$nav-list-side;
				&:first-child{
					margin-left:0;	
				}
			}
			@if $nav-list == right {
				margin-right:$nav-list-side;
				&:first-child{
					margin-right:0;	
				}
			}
		}
		@if $responsive == 'false'{
			ul{
				display:block !important;
			}
		}
		@if $responsive == 'true'{
			background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 0;
			background-size: 32px 64px;
			width:32px;
			height:32px;
			cursor:pointer;
			&.addClose{
				background:url(../../images/common/responsive_nav_2x.png) no-repeat 0 -32px;
				background-size: 32px 64px;
			}
			ul{
				@if $responsive-shadow == 'true'{
					-webkit-box-shadow:0 4px 4px rgba(0,0,0,.12);
					box-shadow:0 4px 4px rgba(0,0,0,.12);
				}
				position:absolute;
				width:100%;
				top:$header-height;
				left:0;
				display:none;
				li{
					margin:0;
					border-top:$responsive-border 1px solid;
					a{
						background:$responsive-background;
						color:$responsive-color;
						display:block;
						padding:$nav-list-top $nav-list-side;
						&:hover{
							opacity:.9;
						}
					}
				}
			}
		}
	}
}


#HEADER{
	/* PC STYLE
	----------------------------------------*/
	@media screen and (min-width:769px){
		@include headerStyle(
			$header-width:100%,
			$header-height:$pc-head,
			$header-x:0,
			$header-y:0,
			$header-top-padding:0,
			$header-right-padding:0,
			$header-bottom-padding:0,
			$header-left-padding:0,
			$header-attachment:fixed,
			$header-nav-position:none,
			//$header-background:#FFF,
			$header-zindex:1000,
			
			$logo-class:'.logo',
			$logo-width:203px,
			$logo-height:auto,
			$logo-position:left,
			$logo-top-margin:20px,
			$logo-right-margin:0,
			$logo-bottom-margin:0,
			$logo-left-margin:0,
			
			$nav-class:'.gnavi',
			$nav-position:right,
			$nav-top-margin:0,
			$nav-right-margin:0,
			$nav-bottom-margin:0,
			$nav-left-margin:0,
			
			$nav-list:left,
			$nav-list-side:40px,
			$nav-list-top:0			
		);
	}
	
	/* SP STYLE
	----------------------------------------*/
	@media screen and (max-width:768px){
		@include headerStyle(
			$header-width:100%,
			$header-height:$sp-head,
			$header-x:0,
			$header-y:0,
			$header-top-padding:0,
			$header-right-padding:12px,
			$header-bottom-padding:0,
			$header-left-padding:12px,
			$header-attachment:fixed,
			$header-nav-position:none,
			//$header-background:#FFF,
			$header-zindex:1000,
			
			$logo-class:'.logo',
			$logo-width:auto,
			$logo-height:30px,
			$logo-position:left,
			$logo-top-margin:15px,
			$logo-right-margin:0,
			$logo-bottom-margin:0,
			$logo-left-margin:0,
			
			$nav-class:'.gnavi',
			$nav-position:right,
			$nav-top-margin:14px,
			$nav-right-margin:0,
			$nav-bottom-margin:0,
			$nav-left-margin:0,
			
			$nav-list:none,
			$nav-list-side:12px,
			$nav-list-top:12px,
			
			$responsive:'true',
			$responsive-color:$normal,
			$responsive-background:rgba(#FFF,.95),
			$responsive-border:#DDD,
			$responsive-shadow:'true'
			
		);		
	}
	
	/* CUSTUM STYLE
	----------------------------------------*/
	padding: 0 0 0 30px !important;
	@media screen and (max-width:768px){
		padding: 0 12px !important;
	}
	a{
		color:$normal;	
	}
	.logo {
		a {
			background: url("../../../images/common/logo.png") no-repeat;
			background-size: 203px 34px;
			width: 203px;
			height: 34px;
			display: block;
			text-indent: -9999px;
		}
	}
	.gnavi {
		background-color: rgba(#FFF,.85);
		padding: 0 0 0 52px;
		@media screen and (max-width:768px){
			padding: 0;
		}
		ul {
			>li {
				a {
					@include flex;
					align-items: center;
					justify-content: center;
					font-weight: 700;
					height: $pc-head;
					@media screen and (max-width:768px){
						height: auto;
					}
					&.link {
						font-size: 14px;
						font-weight: 700;
						position:relative;
						top:0;
						left:0;
						$size:67px;
						&:before{
							content:'';
							width:0;
							height:5px;
							background:$blue;
							position:absolute;
							left:50%;
							bottom:0;
							@include transition(120ms);
							@media screen and (max-width:768px){
								content: none;
							}
						}
						&:hover{
							&:before{
								width:$size;
								margin-left:-$size/2;
							}
						}
					}
					&.contact {
                        font-size: 14px;
						width: 140px;
						@include grad;
						color: #FFF;
						text-indent: -9999px;
						@media screen and (max-width:768px){
							width: 100%;
							background: rgba(#FFF,.95);
							text-indent: 0;
							color: $normal;
						}
						span {
							text-indent: 0;
							background: url("../../../images/common/mail_icon.png") no-repeat top center;
							@include opensans;
							padding: 26px 0 0;
							@media screen and (max-width:768px){
								display: none;
							}
						}
					}
				}
			}
		}
	}
	
	&.active {
		@include transition(120ms);
		background: #FFF;
		box-shadow: 0 0 8px rgba(#000,.12);
		.logo {
			a {
				background: url("../../../images/common/ft_logo.png") no-repeat top 2px left;
				background-size: contain;
			}
		}
	}
}