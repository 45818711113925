.NEWS{
	a{
		color: $normal;
	}
    img{
        margin: 0 0 24px;
    }
	.date_right{
		color: $blue;
		font-weight: 700;
		position: absolute;
		bottom: 6px;
		right: 0;
		@media screen and (max-width:768px){
			position: relative;
			top: 0;
			right: auto;
			left: 0;
			margin: 0 0 8px;
		}
	}
	.post{
		@media screen and (max-width:768px){
			font-size: 14px;
		}
	}
	.list{
		li{
			list-style: none;
			margin: 0 0 15px;
			&:last-child{
				margin: 0;
			}
			a{
				display: block;
				font-size: 15px;
				background: url(../images/common/glay_arrow_2x.png) no-repeat right 15px center;
				border: $border 1px solid;
				padding: 15px;
				padding-right: 32px;
				&:hover{
					background-color: #f5f5f5;
				}
				.date{
					padding: 4px 0;
					width: 100px;
					display: inline-block;
					margin: 0 16px 0 0;
					background: $blue;
					color: #FFF;
					text-align: center;
					font-size: 12px;
                    @media screen and (max-width:768px){
                        width: 80%;
                        display: block;
                        margin: 0 auto 12px;
                    }
				}
			}
		}
	}
    .back{
        line-height: 5;
        position: relative;
        padding: 0 0 0 30px;
    }
    .main{
        figure{
            margin: 24px 0 0;
        }
    }
    div.title{
        position: relative;
    }
}
