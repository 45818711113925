.firstview {
	position: fixed !important;
	top: 0; left: 0;
    width: 100%;
    height: 100vh;
    background: #000;
    overflow: hidden;
    z-index: 0;
    &:before{
        content: '';
        background: rgba(#000,.19);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }
	.content{
		width: 100%;
		max-width: 1120px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 3;
		@include flex();
		align-items: center;
		justify-content: flex-start;
		transform: translate(-50%,0);
		@media screen and (max-width:768px){
			max-width: 100%;
		}
		h2{
			@include opensans;
			font-size: 22px;
			font-weight: 700;
			color: #FFF;
			width: 100%;
			letter-spacing: 0.20em;
			@media screen and (max-width:768px){
				padding: 0 12px;
				font-size: 18px;
			}
			figure {
				display: block;
				margin: 0 0 28px;
				@media screen and (max-width:768px){
					margin: 0 0 14px;
				}
				img {
					max-width: 596px;
					height: auto;
					@media screen and (max-width:768px){
						max-width: 100%;
					}
				}
			}
		}
		.arrowWrap {
		    position: absolute;
		    right: 0;
		    bottom: 0;
		    height: 130px;
			p {
				text-align: end;
				transform: rotate(90deg);
				color: #FFF;
				@include opensans;
				font-size: 9px;
				font-weight: 700;
			}
			.arrow {
				width: 1px;
				height: 130px;
				margin: 20px auto 0;
				background-color: #FFF;
				position: relative;
				overflow: hidden;
				&:before {
					content: '';
					width: 1px;
					height: 130px;
					margin: 20px auto 0;
					background-color: rgba(#000,.55);
					position: absolute;
					top: -150px;
					left: 0;
					-webkit-animation: arrow 2.5s ease 0s infinite normal;
					animation: arrow 2.5s ease 0s infinite normal;
				}
			}
		}		
		
		

	}
	.hide{
		display: none;
	}
}

@keyframes arrow {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  60% {
    -webkit-transform: translate3d(-50%, 100px, 0);
    transform: translate3d(-50%, 100px, 0);
  }

  100% {
    -webkit-transform: translate3d(-50%, 100px, 0);
    transform: translate3d(-50%, 100px, 0);
  }
}
