.CONTACT {
	&.first {
		.txtC {
			text-align: center;
		}
		.formStyle {
			width:100%;
			border-collapse: collapse;
			th {
				border-bottom:1px solid $border;
				padding:25px 0;
				vertical-align: middle;
				text-align: left;
				width: 30%;
				padding-right: 40px;
				&:before {
					content: "任意項目";
					background: #bbbbbb;
					color: #ffffff;
					padding: 2px 10px;
					float: right;
				}
				&.hiss {
					&:before {
						content: "必須項目";
						background: $link;
					}
				}
				@media screen and (max-width:768px){
					display: block;
					width: 100%;
					padding: 12px 0;
				}
			}
			td {
				border-bottom:1px solid $border;
				padding:25px 0;
				background: #ffffff;
				@media screen and (max-width:768px){
					display: block;
					width: 100%;
					padding: 12px 0;
				}
				label {
					display: inline-block;
					margin-right: 5px;
				}
				.mt {
					display: block;
					margin-top: 20px;
				}				
			}
		}
	}
	input[type=text],
	input[type=tel],
	input[type=email],
	textarea,select {
		@include font();
		font-size: 15px;
		background: #f5f5f5;
		width: 100%;
		border: none;
		padding: 5px 10px;
		line-height: 1.8;
		@media screen and (max-width:768px){
			display: block;
			margin: 0 0 5px;
			width: 100% !important;
			background: none;
			border: 1px solid $border;
		}
		&.s { width: 25%; }
		&.m { width: 50%; }
	}
	input[type="submit"],input[type="reset"] {
		border: none;
		@include grad;
		color: #ffffff;
		@include font;
		font-size: 18px;
		display: inline-block;
		padding: 10px 0;
		margin: 0 1%;
		width: 20%;
		cursor: pointer;
		&:hover{
			background: lighten($blue, 5%);
		}
		@media screen and (max-width:768px){
			display: block;
			padding: 12px 0;
			margin: 0 0 10px;
			font-size: 17px;
			font-weight: 700;
			width: 100%;
			text-align: center;
		}
	}
	input[type="reset"] {
		background: darken($border,10%);
		&:hover{
			background: darken($border,5%);
		}
	}
	
}