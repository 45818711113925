.PRIVACY {
	&.first {
		p {
			margin: 0 0 32px;
			@media screen and (max-width:768px){
				margin: 0 0 24px;
			}
		}
		dl {
			border: 10px solid rgba($blue,.10);
			padding: 30px;
			margin: 0 0 24px;
			&:last-of-type {
				margin-bottom: 0;
			}
			@media screen and (max-width:768px){
				padding: 20px;
				margin: 0 0 4%;
				border-width: 5px;
			}
			dt {
				font-size: 18px;
				font-weight: 700;
				padding: 0 0 8px;
				margin: 0 0 12px;
				border-bottom: 1px solid $border;
				color: $blue;
				@media screen and (max-width:768px){
					font-size: 15px;
					line-height: 1.6;
				}
			}
		}
		.disc {
			margin: 12px 0 0;
			>li {
				list-style: disc outside;
				margin-left: 20px;
			}
		}
	}
}