.RECRUIT {
	&.first {
		p.center {
			margin: 0 0 32px;
			font-size: 18px;
			font-weight: 700;
			@media screen and (max-width:768px){
				margin: 0 0 24px;
			}
		}
	}
}