.SITEMAP {
	&.first {
		.fourClum {
			>li {
				@media screen and (min-width:769px){
					@include clum(4,23.5%,2%);
				}
				@media screen and (max-width:768px){
					list-style: none;
					margin: 0 0 2%;
				}
				a {
					display: block;
					@include grad;
					color: #FFF;
					font-size: 18px;
					padding: 12px;
					text-align: center;
					@include transition(100ms);
					&:hover {
						background: $blue;
					}
					@media screen and (max-width:768px){
						font-size: 13px;
					}
				}
			}
		}
	}
}