@charset "utf-8";

@import "module/reset";
@import "module/module";

/* BASE COLOR
------------------------------------------------------------*/
$normal:#000;
$link:#D02;
$border:#DDD;

$blue:#4f76d3;
$yellow:#ddab00;
@mixin grad($color:#4a68d5, $deg:-31deg) {
  background: {
    color: $color;
    image: linear-gradient(to left,adjust-hue($color, $deg) 0%, $color 100%);
  }
}

/* FONT SETTING
------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700,800&display=swap');
@mixin opensans {
	font-family: 'Open Sans', sans-serif;
}

/* SITE SETTING
------------------------------------------------------------*/
$width:1120px;
$pc-head:80px;
$sp-head:60px;


@media screen and (min-width:769px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:768px){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#EEE //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "page/firstview";
@import "page/index";
@import "page/advantage";
@import "page/business";
@import "page/environmental";
@import "page/construction";
@import "page/company";
@import "page/recruit";
@import "page/contact";
@import "page/privacy";
@import "page/sitemap";
@import "page/news";


#WRAPPER {
    position: relative;
    top: $pc-head;
	left: 0;
	z-index: 1;
	background: #FFF;
    @media screen and (max-width:768px){
        top: $sp-head;
    }
}
#FOOTER{
	position: relative;
	top: $pc-head;
	@media screen and (max-width:768px){
		top: $sp-head;
	}
}

.motion-animate{
	opacity: 0;
	transform: translateY(-40px);
	transition: all .8s cubic-bezier(0.645, 0.045, 0.355, 1);
	&.active{
		transform: translateY(0);
		opacity: 1;
	}
}


.MODULE{
	&.wrap {
		padding: 72px 0;
		@media screen and (max-width:768px){
			padding: 32px 12px;
		}
		&.no-top {
			padding-top: 0;
		}
	}
	h3 {
		margin: 0 0 34px;
		@media screen and (max-width:768px){
			margin: 0 0 24px;
		}
		&.center {
			text-align: center;
			background: url("../../images/common/h3_01.png") no-repeat bottom center;
			background-size: 36px 3px;
			font-size: 30px;
			font-weight: 700;
			padding: 0 0 15px;
			@media screen and (max-width:768px){
				font-size: 23px;
				padding-bottom: 8px;
			}
		}
		&.index {
			text-align: center;
			background: url("../../images/common/h3_02.png") no-repeat top center;
			font-size: 16px;
			font-weight: 700;
			@media screen and (max-width:768px){
				font-size: 12px;
			}
			&:before {
				content: attr(data-eng);
				display: block;
				@include opensans;
				font-size: 42px;
				font-weight: 800;
				padding: 19px 0 10px;
				@media screen and (max-width:768px){
					font-size: 26px;
					padding: 12px 0 0;
				}
			}
		}
	}
	h4 {
		margin: 0 0 24px;
		@media screen and (max-width:768px){
			margin: 0 0 18px;
		}
		&.border {
			border-bottom: 1px solid $border;
			font-size: 24px;
			font-weight: 700;
			padding: 0 0 0 16px;
			position: relative;
			@media screen and (max-width:768px){
				font-size: 20px;
			}
			&:before {
				content: "";
				width: 4px;
				height: 70%;
				background: $blue;
				position: absolute;
				top: 8px; left: 0;
			}
		}
	}
	.btnArea {
		margin: 32px 0 0;
		@media screen and (max-width:768px){
			margin: 24px 0 0;
		}
		a {
			display: inline-block;
			font-size: 16px;
			padding: 12px 82px;
			font-size: 16px;
			font-weight: 700;
			@media screen and (max-width:768px){
				display: block;
				padding: 12px 0;
				text-align: center;
			}
			&.grad {
				@include grad;
				color: #FFF;
				padding: 0;
			}
			.arw {
				display: block;
				background: url("../../images/common/arw_01_2x.png") no-repeat right 18px center;
				background-size: 9px 16px;
				padding: 12px 82px;
				&:hover {
					@include transition(200ms);
					background-position: right 15px center;
				}
			}
		}
	}
	.tableStyle {
		width:100%;
		border:1px solid $border;
		border-collapse: collapse;
		th {
			border:1px solid $border;
			padding:15px;
			vertical-align: middle; text-align: left;
			background: #f5f5f5;
		}
		td {
			border:1px solid $border;
			padding:15px;
			background: #ffffff;
		}
		@media screen and (max-width:768px){
			th,td {
				padding: 10px;
			}
			&.block {
				border-bottom: none;
				th,td {
					display: block;
					border: none;
					border-bottom: 1px solid $border;
				}
				th {
					text-align: center;
				}
			}
		}
	}
	.center {
		text-align: center;
		@media screen and (max-width:768px){
			text-align: left;
		}
		i {
			display: inline-block;
			color: $blue;
			text-decoration: underline;
		}
	}
}

#SecondView {
	width: 100%;
	height: 280px;
	@include flex;
	align-items: center;
	justify-content: center;
	background: url("../../images/common/secondView.jpg") no-repeat center;
	background-size: cover;
	position: relative;
	@media screen and (max-width:768px){
		height: 180px;
	}
	&:before {
		content: "";
		width: 100%;
		height: 100%;
		background: rgba(#000,.50);
		position: absolute;
		top: 0; left: 0;
	}
	h2 {
		font-size: 12px;
		font-weight: 700;
		color: #FFF;
		text-align: center;
		position: relative;
		&:before {
			content: attr(data-eng);
			display: block;
			@include opensans;
			font-size: 42px;
			font-weight: 700;
			@media screen and (max-width:768px){
				font-size: 28px;
			}
		}
	}
	nav {
		width: 100%;
		position: absolute;
		bottom: 12px; left: 0;
		ol{
			@media screen and (max-width:768px){
				padding: 0 12px;
			}
			li{
				list-style:none;
				color:#FFF;
				font-size:12px;
				background:url(../../images/common/arrow_2x.png) no-repeat right center;
				background-size: 4px;
				padding:0 16px 0 0;
				margin:0 12px 0 0;
				float:left;
				@media screen and (max-width:768px){
					font-size: 10px;
				}
				&:last-child{
					margin:0;
					padding:0;
					background:none;
				}
				a{
					color:#FFF;
					&:hover{
						opacity:.88;
					}
				}
			}
		}				
		
		
	}
}