.COMPANY {
	&.first {
		.tableStyle {
			td {
				.two {
					@include flex;
					justify-content: flex-start;
					dt {
						width: 14%;
						@media screen and (max-width:768px){
							width: 35%;
						}
					}
					dd {
						width: 86%;
						@media screen and (max-width:768px){
							width: 65%;
						}
					}
				}
			}
		}
	}
}