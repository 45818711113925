.INDEX {
	&.first {
		background: url("../../../images/index/back_02.png") no-repeat center bottom;
		background-size: cover;
		.center {
			font-weight: 700;
		}
		.flex {
			@include flex;
			margin: 42px 0 0;
			counter-reset: num;
			@media screen and (max-width:768px){
				margin: 24px 0 0;
			}
			>li {
				list-style: none;
				width: 31%;
				background: #FFF;
				box-shadow: 0 0 8px rgba(#000,.2);
				@media screen and (max-width:768px){
					width: 100%;
					margin: 0 0 4%;
				}
				figure {
					position: relative;
					&:before {
						content: counter(num, decimal-leading-zero);
						counter-increment: num;
						@include opensans;
						color: #FFF;
						font-size: 24px;
						font-weight: 700;
						width: 56px;
						height: 56px;
						display: block;
						text-align: center;
						padding-top: 6px;
						background: $blue;
						position: absolute;
						bottom: 0; left: 0;
					}
					
					img {
						max-width: 100%;
						height: auto;
					}
				}
				dl {
					padding: 30px;
					@media screen and (max-width:768px){
						padding: 20px;
					}
					dt {
						text-align: center;
						font-size: 24px;
						font-weight: 700;
						border-bottom: 1px solid $border;
						padding: 0 0 8px;
						margin: 0 0 16px;
						@media screen and (max-width:768px){
							font-size: 18px;
						}
						&:before {
							content: attr(data-eng);
							color: $yellow;
							@include opensans;
							font-size: 11px;
							font-weight: 700;
							display: block;
							@media screen and (max-width:768px){
								font-size: 10px;
							}
						}
					}
				}
			}
		}
	}
	
	&.second {
		.center {
			font-weight: 700;
		}
		.flex {
			@include flex;
			margin: 42px 0 0;
			>li {
				width: 50%;
				min-height: 270px;
				position: relative;
				@for $i from 1 through 4{
					&:nth-child(#{$i}){
						background: url("../../../images/index/photo_2_0#{$i}.jpg") no-repeat center;
						background-size: cover;
						dl {
							dt {
								background: url("../../../images/index/icon_0#{$i}.png") no-repeat top center;
							}
						}
					}
				}
				@media screen and (max-width:768px){
					width: 100%;
					min-height: inherit;
					padding: 20px;
					margin: 0 0 4%;
				}
				dl {
					width: 52%;
					background: #FFF;
					padding: 40px;
					box-shadow: 0px 3px 30px 3px rgba(0, 0, 0, 0.25);
					position: absolute;
					right: 32px; bottom: -50px;
					z-index: 2;
					@media screen and (max-width:768px){
						width: 95%;
						margin: 0 auto;
						padding: 20px;
						position: static;
						background: rgba(#FFF,.85);
					}
					dt {
						color: $blue;
						text-align: center;
						font-size: 24px;
						font-weight: 700;
						padding: 38px 0 8px;
						@media screen and (max-width:768px){
							font-size: 18px;
							padding-top: 30px;
						}
					}
					dd {
						font-size: 16px;
						@media screen and (max-width:768px){
							font-size: 13px;
						}
					}
				}
			}
		}
		.btnArea {
			margin-top: 92px;
			@media screen and (max-width:768px){
				margin-top: 24px;
			}
		}
	}
	
	&.third {
		overflow: hidden;
		position: relative;
		&:before{
			content: "";
			width: 100%;
			height: 300px;
			background: $blue;
			position: absolute;
			bottom: 0; left: 0;
            @media screen and (max-width:768px){
                height: 165px;
            }
		}
		ul {
			>li {
				box-shadow: 0 0 15px rgba(#000,.15);
				background: #FFF;
				@media screen and (max-width:768px){
					box-shadow: 0 0 5px rgba(#000,.15);
				}
				figure {
					img {
						max-width: 100%;
						height: auto;
					}
				}
				figcaption {
					text-align: center;
					font-size: 16px;
					font-weight: 700;
					padding: 15px 0;
				}
			}
		}
		.swiper-container {
			width: 100%;
			overflow: visible;
		}
		.swiper-pagination {
			bottom: -40px;
			@media screen and (max-width:768px){
				display: none;
			}
		}
		.swiper-pagination-bullet {
			background: #FFF;
			border: 1px solid #FFF;
		}
		/*
		.swiper-button-next,.swiper-button-prev {
			width: 108px;
			height: 108px;
			margin-top: -54px;
		}
		.swiper-button-next {
			background: url("../../../images/index/next.png") no-repeat center;
			background-size: 108px;
		}
		.swiper-button-prev {
			background: url("../../../images/index/prev.png") no-repeat center;
			background-size: 108px;
		}
		*/
		
	}
	
	
	&.four {
		.flex {
			@include flex;
			.news,.env {
				width: 50%;
				@media screen and (max-width:768px){
					width: 100%;
				}
				.block {
					width: calc(1120px / 2);
					padding: 42px 0;
					position: relative;
					@media screen and (max-width:768px){
						width: 100%;
						padding: 32px 12px !important;
					}
				}
			}
            .env{
                border-bottom: solid 1px lighten($blue,20%);
            }
			.news {
				background: #325bbc;
				.block {
					margin-left: auto;
					padding-right: 42px;
					@media screen and (max-width:768px){
						margin: 0 auto;
					}
                    .btnArea{
                        margin: 48px 0 0;
                    }
				}
				.news_list {
					background: #fff;
					max-height: 160px;
					overflow-y: scroll;
					padding: 18px 30px ;
					@media screen and (max-width:768px){
						padding: 20px;
					}
					>li {
						list-style: none;
						padding: 12px 0;
						border-bottom: 1px solid #e5e5e5;
						a {
							display: block;
							color: $normal;
							time {
								display: inline-block;
								background: $blue;
								font-size: 11px;
								font-weight: 700;
								color: #fff;
								padding: 5px 10px;
								margin-right: 20px;
								@media screen and (max-width:768px){
									display: block;
									width: 25%;
									text-align: center;
									padding: 2px 0;
									margin-bottom: 5px;
								}
							}
						}
					}					
				}
				.more{
					background: url("../../../images/common/news_icon.png") no-repeat 46px center darken($blue,25%);
					background-size: 26px 17px;
					font-size: 16px;
					font-weight: 700;
					display:inline-block;
					text-align:center;
					color:#FFF;
					padding: 12px 72px 12px 98px;
					@media screen and (max-width:768px){
						display: block;
						padding: 12px;
					}
				}				
			}
			.env {
				background: url("../../../images/index/back_01.jpg") no-repeat center;
				background-size: cover;
				position: relative;
				&:before {
					content: "";
					width: 100%;
					height: 100%;
					background: rgba(#FFF,.80);
					position: absolute;
					top: 0; left: 0;
				}
				h3.index {
					font-size: 14px;
					@media screen and (max-width:768px){
						font-size: 13px;
					}
					&:before {
						font-size: 26px;
						@media screen and (max-width:768px){
							font-size: 22px;
						}
					}
				}
				.block {
					padding-left: 42px;
					@media screen and (max-width:768px){
						padding-left: auto;
					}
				}
			}
		}
	}
	&.five {
		.info {
			background: #FFF;
			@include flex;
			align-items: center;
			.plofile {
				width: 50%;
				max-width: calc(1120px / 2);
				margin-left: auto;
				padding-right: 5%;
				@media screen and (max-width:768px){
					width: 100%;
					max-width: 100%;
					margin: 0 auto;
					padding: 32px 12px;
				}
				dt {
					padding: 0 0 24px;
					span {
						display: block;
						color: $blue;
						@include opensans;
						padding: 0 0 12px;
						font-size: 12px;
						font-weight: 700;
					}
					figure {
						img {
							width: auto;
							height: 40px;
						}
					}
				}
				dd {
					&:first-of-type {
						border-bottom: 1px solid $border;
						padding: 0 0 12px;
						margin: 0 0 12px;
					}
					span {
						display: inline-block;
						@include opensans;
						&:first-of-type {
							margin-right: 10%;
						}
						i {
							display: inline-block;
							font-size: 13px;
							background: $blue;
							color: #FFF;
							padding: 3px 20px;
							margin-right: 14px;
						}
                        a{
                            color: $blue;
                            text-decoration: underline;
                            @media screen and (min-width:769px){
                                pointer-events: none;
                                color: $normal;
                                text-decoration: none;
                            }
                        }
					}
				}
			}
			.mapArea {
				width: 50%;
				height: 350px;
				@media screen and (max-width:768px){
					width: 100%;
					height: 200px;
					iframe {
						width: 100%;
						height: 200px;
					}
				}
			}
		}
	}
	
}