.ADVANTAGE {
	&.first {
		.oneClum {
			margin: 42px 0 0;
			counter-reset: num;
			@media screen and (max-width:768px){
				margin: 24px 0 0;
			}
			>li {
				list-style: none;
				padding: 50px 0;
				margin: 0 0 48px;
				@for $i from 1 through 3{
					&:nth-child(#{$i}){
						background: url("../../../images/advantage/back_0#{$i}.png") no-repeat center;
						background-size: cover;
					}
				}
				&:nth-child(odd){
					dl {
						margin-left: auto;
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
				@media screen and (max-width:768px){
					padding: 20px;
					margin: 0 0 4%;
				}
				dl {
					width: 74%;
					padding: 50px;
					background: #FFF;
					box-shadow: 0px 0px 26px 0.55px rgba(0, 0, 0, 0.23);
					@media screen and (max-width:768px){
						width: 100%;
						padding: 20px;
						background: rgba(#FFF,.75);
					}
					dt {
						font-size: 22px;
						font-weight: 700;
						border-bottom: 1px solid $border;
						padding: 0 0 12px;
						margin: 0 0 16px;
						@media screen and (max-width:768px){
							font-size: 18px;
						}
						&:before {
							counter-increment: num;
							content: counter(num);
							@include opensans;
							font-size: 17px;
							font-weight: 700;
							color: #FFF;
							background: $blue;
							display: inline-block;
							width: 30px; height: 30px;
							text-align: center;
							margin-right: 16px;
						}
					}
				}
			}
		}
		
	}
	
}