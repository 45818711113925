.CONSTRUCTION {
	&.first {
		.locals{
			li{
				@media screen and (min-width:769px){
					@include clum(5,18.4%,2%);
				}
				@media screen and (max-width:768px){
					margin: 0 0 16px;
					&:last-child{
						margin: 0;
					}
				}
				a{
					display: block;
					text-align: center;
					padding: 8px 0;
					color: $normal;
					cursor: pointer;
					font-size: 15px;
					border-radius: 6px;
					background: rgba($blue,.10);
					&:hover{
						background: #f2f2f2;
					}
				}
			}
		}
		#props{
			margin: 64px 0 0;
			@media screen and (max-width:768px){
				margin: 32px 0 0;
			}
			.tableProp{
				border: $border 1px solid;
				border-collapse: collapse;
				width: 100%;
				th,td{
					border: $border 1px solid;
					padding: 8px 16px;
					font-size: 15px;
					@media screen and (max-width:768px){
						padding: 8px 15px;
						font-size: 12px;
					}
				}
				tr{
					&:first-child{
						th,td{
							background: #f2f2f2;
						}
					}
					td{
						&:nth-child(2){
							width: 25%;
							@media screen and (max-width:768px){
								width: 24%;	
							}
						}
						&:nth-child(3){
							width: 20%;
							@media screen and (max-width:768px){
								width: 16%;	
							}
						}
					}
				}
			}
		}		
		
	}
	
	&.second {
		.Clum {
			>li {
				@media screen and (min-width:769px){
					@include clum;
				}
				@media screen and (max-width:768px){
					list-style: none;
					margin: 0 0 2%;
				}
				figure {
					img {
						max-width: 100%;
						height: auto;
					}
				}
			}
		}
	}
}